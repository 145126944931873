<template>
	<div id="access">
		<!-- ##### Viral News Breadcumb Area Start ##### -->
		<div class="viral-news-breadcumb-area section-padding-50">
			<div class="container h-100">
				<div class="row h-100 align-items-center">
					<!-- Breadcumb Area -->
					<div class="col-12 col-md-4">
						<h3>アクセス</h3>
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item"><a href="/">Home</a></li>
								<li class="breadcrumb-item active" aria-current="page">
									アクセス
								</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Viral News Breadcumb Area End ##### -->

		<!-- ##### Contact Form Area Start ##### -->
		<div class="block section-padding-50">
			<div class="container">
				<div class="row">
					<div class="col-12 col-lg-8">
						<div class="map_wrapper">
							<div class="googlemap">
								<iframe
									frameborder="0"
									scrolling="no"
									marginheight="0"
									marginwidth="0"
									src="https://maps.google.co.jp/maps?q=京都府京都市山科区音羽前田町48-1&z=15&output=embed&iwloc=J&t=m"
								></iframe>
							</div>
						</div>
					</div>

					<div class="detail mb-4 col-12 col-lg-4">
						<table class="table table-striped">
							<tr>
								<th>所在地</th>
								<td>
									<h6>ショールーム（受付等）</h6>
									<p>
										〒607-8067 京都府京都市山科区音羽前田町48-1（<a
											href="http://goo.gl/ryxqF0"
											target="_blank"
											>Google Maps</a
										>）
									</p>
									<h6>メイン工場（COOLGUSH）</h6>
									<p>
										〒607-8067 京都府京都市山科区音羽前田町35-1（<a
											href="http://goo.gl/nHPfDu"
											target="_blank"
											>Google Maps</a
										>）
									</p>
								</td>
							</tr>
							<tr>
								<th>営業時間</th>
								<td>10：00～18：00</td>
							</tr>
							<tr>
								<th>定休日</th>
								<td>月曜日、第2・4火曜日</td>
							</tr>
							<tr>
								<th>電話</th>
								<td>075-582-7788</td>
							</tr>
							<tr>
								<th>FAX</th>
								<td>075-320-2978</td>
							</tr>
							<tr>
								<th>E-MAIL</th>
								<td>mail@assist-kyoto.co.jp</td>
							</tr>
						</table>

						<p class="text-center">
							<a
								href="https://form.run/@assist-kyoto"
								target="_blank"
								class="btn add-post-btn"
								>問い合わせ</a
							>
						</p>
					</div>
				</div>

				<div class="row">
					<div class="col-12 bycar">
						<div class="headline">
							<h3>お車でお越しの際は</h3>
						</div>
						<div class="row">
							<div class="col-12 col-lg-8">
								<dl>
									<dt>名神高速から</dt>
									<dd>
										京都東ＩＣ出口から国道1号線清水寺方面に合流、1つ目の信号先左手にあります
									</dd>
									<dt>京都市内国道1号線、外環状線から</dt>
									<dd>
										外環状線からは国道１号線を名神高速「京都東ＩＣ」入口手前信号右手にあります
									</dd>
									<dt>京都市内三条通りから</dt>
									<dd>
										名神高速「京都東ＩＣ」入口横左手に進み高架を潜ったところで中央分離帯切れ目を右折し20ｍ先国道１号線を右に合流、1つ目の信号先左手にあります
									</dd>
								</dl>
							</div>
							<div class="col-12 col-lg-4">
								<img
									src="/img/about/access/route.jpg"
									class="img-fluid"
									alt="お車でお越しの際は"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Contact Form Area End ##### -->
	</div>
</template>

<script>
export default {
	title: "アクセス",
	components: {},
};
</script>

<style scoped lang="scss">
.detail {
	h6 {
		margin-bottom: 1rem;
	}
}
.bycar {
	dt {
		font-size: 1rem;
		padding: 0.4rem;
		span {
			background-color: #f3f5f8;
		}
	}
	dd {
		padding-left: 0.4rem;
		margin-bottom: 1rem;
	}
}
.map_wrapper {
	border: 1px solid #ccc;
	margin-bottom: 20px;
	max-width: 840px;
	min-width: 280px;
	padding: 4px;
}
.googlemap {
	height: 400px;
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	iframe {
		height: 100% !important;
		left: 0;
		position: absolute;
		top: 0;
		width: 100% !important;
	}
}
table {
	th {
		width: 30%;
	}
}
</style>
